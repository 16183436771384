import db from "astrid-firebase/src/db";
import producerRoles from "astrid-firestore/src/api/organizations/constants/producerRoles";
import {
	isReaderOccupied,
	isRecorderOccupied,
	isStudioOccupied,
} from "astrid-firestore/src/api/sessions/utils/sessionOverlap";
import { useCollectionData, useLoadingValues } from "astrid-firestore/src/hooks";
import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

import EngineerResourceTitle from "../components/EngineerResourceTitle";
import ProductionResourceTitle from "../components/ProductionResourceTitle";
import StudioResourceTitle from "../components/StudioResourceTitle";

import useEngineerResources from "./useEngineerResources";
import useProductionResources from "./useProductionResources";
import useStudioResources from "./useStudioResources";

export default function useResourceTypes({ organization, sessions }) {
	const [[studiosGen1 = [], studiosGen2 = [], engineers = []], loading, error] = useLoadingValues(
		useCollectionData(
			organization &&
				db.collection("organizations").doc(organization.id).collection("studios").where("active", "==", true),
		),
		useCollectionDataOnce(organization && db.collection("studios").where("producerId", "==", organization.id)),
		useCollectionDataOnce(
			organization &&
				db
					.collection("users")
					.where(`permissions.producer.${organization.id}`, "array-contains", producerRoles.RECORDER),
		),
	);

	const studios = studiosGen1.concat(studiosGen2);

	const studioResources = useStudioResources({ studios });
	const engineerResources = useEngineerResources({ engineers });
	const productionResources = useProductionResources({ sessions });

	const resourceTypes = {
		studios: {
			resources: studioResources.resources,
			resourceColors: studioResources.resourceColors,
			resourceTitle: (props) => <StudioResourceTitle {...props} />,
			getSessionOrder: (session) => {
				const studio = studios.find((studio) => studio.id === session.studio);

				return studio ? `${studio.groupTags[0]} ${studio.name}` : "";
			},
			getResourceId: (session) => session.studio || session.studioGroup,
			getOverlappingResourceIds: () => studios.map((studio) => studio.id),
			checkOverlap: (session, { recorder, reader }) => {
				return isRecorderOccupied({ session, recorder }) || isReaderOccupied({ session, reader });
			},
		},
		engineers: {
			resources: engineerResources.resources,
			resourceColors: engineerResources.resourceColors,
			resourceTitle: (props) => <EngineerResourceTitle {...props} />,
			getResourceId: (session) => session.recorder,
			getOverlappingResourceIds: (session) => [session.recorder],
			checkOverlap: (session, { reader, studio }) => {
				return isStudioOccupied({ session, studio }) || isReaderOccupied({ session, reader });
			},
		},
		productions: {
			resources: productionResources.resources,
			resourceColors: productionResources.resourceColors,
			resourceTitle: (props) => <ProductionResourceTitle {...props} />,
			getResourceId: (session) => session.production,
		},
	};

	return {
		resourceTypes,
		loading,
		error,
		getBackgroundColor: (session) => studios.find((studio) => studio.id === session.studio)?.color,
	};
}
