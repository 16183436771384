import { createSelector } from "reselect";

import selectPermissions from "./selectPermissions";

const selectHasRole = createSelector(
	selectPermissions,
	(permissions) =>
		(...roles) =>
			roles.includes(permissions.role),
);

export default selectHasRole;
