export default {
	name: "Bookwire",
	format: "wav",
	connection: "ftp",
	oneByOneUpload: true, // only supports zipped audio atm.
	meta: {
		customRemoteDir: "xml",
		folder: "meta",
		format: "onix",
		fields: ["availability", "countriesIncluded"], // extra metadata fields
		sharedFields: ["tags"], // extra shared metadata fields
		//markets: { SE: "Sverige", FI: "Finland", UK: "Storbritannien", DE: "Tyskland" }, // values for the markets option above
	},
	cover: {
		folder: "ISBN",
		square: true,
		min: 3000,
	},
	audio: {
		folder: "ISBN",
		rename: "SEQ_ISBN.SUFFIX",
		zip: true,
	},
	ebook: {
		folder: "ISBN",
	},
	disableArticles: ["cd", "mp3cd"],
	email: ["delivery"],
	shops: {
		3169000: {
			name: "Audiobooks.com",
		},
		3112000: {
			name: "Apple Books (Audiobook)",
		},
		3101001: {
			name: "Bookbeat",
		},
		3094000: {
			name: "Google Play Audio",
		},
		3232001: {
			name: "Fonolibro",
		},
		2012001: {
			name: "Kobo",
		},
		3184002: {
			name: "Elisa Kirja",
		},
		3130000: {
			name: "Nextory",
		},
		3192002: {
			name: "Supla+",
		},
		3243000: {
			name: "Yes24",
		},
		3214008: {
			name: "Audio Comics (Storyside Korea)",
		},
		3057000: {
			name: "Audioteka",
		},
	},
};
