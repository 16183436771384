import { useTranslation } from "react-i18next";

import updateDocument from "astrid-firestore/src/helpers/updateDocument";

import api from "../../../../../api";
import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

export default function UserNarratorSettings({ user }) {
	const { t } = useTranslation();

	const [organizationOptions] = api.organizations.useOptions();

	const { readerData } = user;

	const form = useSimpleForm({
		defaultValues: {
			reader: !!user.readerData,
			searchable: readerData?.searchable || false,
			selfRecorder: readerData?.selfRecorder || false,
			visibleContactDetails: user.visibleContactDetails || false,
			organizationIds: user.organizationIds || [],
		},

		onSubmit: ({ reader, searchable, selfRecorder, visibleContactDetails, organizationIds }) => {
			return updateDocument(user.ref, {
				reader: reader ? ["any", "allProducers", "allPublishers"] : false,
				"readerData.searchable": searchable,
				"readerData.selfRecorder": selfRecorder,
				organizationIds,
				visibleContactDetails,
			});
		},
	});

	const { watch } = form;

	const { reader } = watch();

	return (
		<AdminForm header={t("narratorSettings", "Narrator settings")} {...form}>
			<Form.Group>
				<Form.Checkbox
					name="reader"
					checked={reader}
					slider
					label={t("narrator", "Narrator")}
					rules={{ required: false }}
				/>
				{reader && (
					<>
						<Form.Checkbox
							name="searchable"
							label={t("searchable")}
							shouldUnregister={false}
							rules={{ required: false }}
						/>
						<Form.Checkbox
							name="visibleContactDetails"
							label={t("visibleDetails")}
							shouldUnregister={false}
							rules={{ required: false }}
						/>
						<Form.Checkbox
							name="selfRecorder"
							label={t("technicianForOwnRec")}
							shouldUnregister={false}
							rules={{ required: false }}
						/>
					</>
				)}
			</Form.Group>

			{reader && (
				<Form.Select
					name="organizationIds"
					label={t("organizations", "Organizations")}
					shouldUnregister={false}
					rules={{ required: false }}
					multiple
					search
					options={organizationOptions.filter((option) => option.value !== option.text)}
				/>
			)}
		</AdminForm>
	);
}
