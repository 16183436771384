import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import sessionErrors from "astrid-firestore/src/api/sessions/constants/sessionErrors";

export default function useSessionErrorTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[sessionErrors.STUDIO_OCCUPIED]: t("studioOccupied", "Studio is occupied"),
			[sessionErrors.READER_OCCUPIED]: t("narratorOccupied", "Narrator is occupied"),
			[sessionErrors.RECORDER_OCCUPIED]: t("recorderOccupied", "Recording engineer is occupied"),
		}),
		[t],
	);
}
