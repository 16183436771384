import { getArticleType } from "./articles";

export function getArticleDistributionOptions({ production, article }) {
	const articleType = getArticleType(article);

	return production?.distributionOptions?.[articleType];
}

export function getArticleDistributionSettings({ production, article, distributor }) {
	const articleType = getArticleType(article);

	return production?.distributionOptions?.settings?.[articleType]?.[distributor];
}
