import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import AgreementActiveStatus from "../components/AgreementActiveStatus/AgreementActiveStatus";
import AgreementStatus from "../components/AgreementStatus/AgreementStatus";

export default function useAgreementColumns() {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "created",
				Header: t("created", "Created"),
				accessor: ({ created }) => created?.toDate().getTime() || "",
				Cell: ({ value }) => new Date(value).toLocaleString(),
			},
			{
				id: "name",
				Header: t("name"),
			},
			{
				id: "secondParty.name",
				Header: t("secondParty", "Second party"),
			},
			{
				id: "status",
				Header: t("status", "Status"),
				Cell: ({ row }) => <AgreementStatus agreement={row.original} />,
			},
			{
				id: "time",
				Header: t("time", "Time"),
				Cell: ({ row }) => <AgreementActiveStatus agreement={row.original} />,
			},
		],
		[t],
	);
}
