import get from "lodash/get";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Dropdown, Form, Header, Icon, Popup, Statistic } from "semantic-ui-react";

import prodGenres from "astrid-config/src/genres/storytel";
import { firebase, hasRight } from "astrid-firebase";
import { codes } from "astrid-helpers/src/currencies";
import MasterSoundSetting from "astrid-web/src/components/MasterSoundSetting";

import DateTime from "../../features/ui/components/DateInput/DateTime";
import { withStore } from "../../helpers/context";
import { toDate } from "../../helpers/fnc";

import RecordButton from "../RecordButton";
import Right from "../Right";

import UserGenre from "./UserGenre";
import UserLanguage from "./UserLanguage";

// const updateReaderStats = firebase.functions().httpsCallable("updateReaderStats");

class UserReader extends Component {
	state = {
		upload: null,
		uploadedImg: null,
		unavailableStart: moment().add(7, "days").toDate(),
		unavailableEnd: moment().add(14, "days").toDate(),
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { t } = this.props;
		if (this.state.uploadedImg && nextProps.profile.img !== this.state.uploadedImg) {
			this.setState({ upload: t("ready"), uploadedImg: null });
		}
	}

	componentDidMount() {
		// open sample box if no sampled
		if (
			!this.props.profile.readerData ||
			!this.props.profile.readerData.samples ||
			!Object.keys(this.props.profile.readerData.samples).length
		)
			this.setState({ newSample: {} });

		// load users for admins
		if (hasRight(this.props.store, "createProducerStaff")) this.props.store.getUsers();
	}

	relayChange = (e, data) => {
		const readerData = this.props.profile.readerData ? { ...this.props.profile.readerData } : {};

		if (data.type === "checkbox") data.value = data.checked;
		readerData[data.name] = data.value;

		this.props.handleChange(e, { name: "readerData", value: readerData });
	};

	relayChangeProfile = (e, data) => {
		if (data.type === "checkbox") data.value = data.checked;

		this.props.handleChange(e, data);
	};

	producerSettingChange = (e, prodId, data) => {
		const readerData = this.props.profile.readerData ? { ...this.props.profile.readerData } : {};
		readerData.producerSettings = readerData.producerSettings || {};
		readerData.producerSettings[prodId] = readerData.producerSettings[prodId] || {};
		readerData.producerSettings[prodId][data.name] = data.value;

		this.props.handleChange(e, { name: "readerData", value: readerData });
	};

	storageRef = firebase
		.storage()
		.refFromURL(window.ES.stage ? "gs://stage-earselect-static" : "gs://earselect-static");

	addSample = () => {
		const sampleId = this.state.newSample.id || +new Date();

		// audio file
		const file = this.state.newSample.file;
		if (!file) return;

		// start upload
		const uploadTask = this.storageRef
			.child("profileAudioSamples/" + this.props.uid + "/sample-" + sampleId + ".mp3")
			.put(file);

		// upload status events
		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				// progress
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				this.setState({ sampleUpload: progress + "%" });
			},
			(error) => {
				// error
				console.log(error);
			},
			() => {
				// success, store in db
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					const readerData = this.props.profile.readerData ? { ...this.props.profile.readerData } : {};

					if (!readerData.samples) readerData.samples = {};
					const sampleData = { ...this.state.newSample };
					sampleData.file = downloadURL;
					readerData.samples[sampleId] = sampleData;

					this.props.handleChange(null, { name: "readerData", value: readerData });
					this.setState({ newSample: null, newSampleErrors: false, sampleUpload: null });
				});
			},
		);
	};

	render() {
		const { t, profile, self, store, uid } = this.props;
		const readerData = profile.readerData || {};

		let adminProducerOrg, admins;
		if (hasRight(this.props.store, "createProducerStaff")) {
			// MEMO: just gets first producer id... should be able to choose somehow
			adminProducerOrg =
				get(store, "state.profile.permissions.producer") &&
				Object.keys(store.state.profile.permissions.producer)[0];

			admins =
				store.state.users &&
				Object.values(store.state.users)
					.filter(
						(user) =>
							user.permissions &&
							user.permissions.producer &&
							user.permissions.producer[adminProducerOrg] &&
							user.permissions.producer[adminProducerOrg].includes("producerAdmin"),
					)
					.map((user, i) => user.id);
		}

		const genreOptions = Object.entries(prodGenres.sv).map(([key, genre]) => ({
			key: key,
			value: key,
			//text: genre,
			text: t(key),
		}));
		return (
			<Form loading={false} as="div" className="reader-profile">
				<Form.Group widths="equal">
					<div className="field">
						<Header as="h4" icon="microphone" content={t("readerCharacteristics")} />
						{store.state.profile.permissions.super && profile.referrer && (
							<p>
								<b>{t("invitedBy") + ":"}</b> {profile.referrer}
							</p>
						)}
						<Form.TextArea
							label={t("presentation")}
							name="presentation"
							value={readerData.presentation}
							onChange={this.relayChange}
						/>
					</div>
					<div className="field">
						<UserGenre profile={profile} handleChange={this.relayChange} />
					</div>
				</Form.Group>
				<Form.Group widths="equal" style={{ marginTop: "2em" }}>
					<div className="field">
						<Header as="h4" icon="play" content={t("soundSamples")} />
						{!this.state.newSample ? (
							<>
								<Button
									color="teal"
									labelPosition="left"
									icon="plus"
									content={t("addSoundSamples")}
									disabled={Object.keys(readerData?.samples || {})?.length > 4}
									onClick={(e) => {
										e.preventDefault();
										this.setState({ newSample: {} });
									}}
								/>

								{readerData.samples &&
									Object.keys(readerData.samples)
										.sort()
										.map((sampleId) => {
											const sample = readerData.samples[sampleId];
											return (
												<div key={sampleId}>
													<div
														style={{
															display: "flex",
															alignItems: "center",
															marginTop: "1em",
														}}
													>
														<a
															href="/"
															onClick={(e) => {
																e.preventDefault();
																if (!window.confirm(t("confirmDeleteSoundSample"))) {
																	return;
																}

																var sampleRef = this.storageRef.child(
																	"profileAudioSamples/" +
																		this.props.uid +
																		"/sample-" +
																		sampleId +
																		".mp3",
																);

																// delete the file
																sampleRef
																	.delete()
																	.then(() => {
																		console.log("sample file deleted");
																	})
																	.catch((error) => {
																		console.error(error);
																	});

																// delete from profile
																const samples = { ...readerData.samples } || {};
																delete samples[sampleId];
																this.relayChange(null, {
																	name: "samples",
																	value: samples,
																});
															}}
														>
															<Icon name="trash alternate" color="black" />
														</a>
														<Dropdown
															options={genreOptions}
															placeholder={t("stateGenre")}
															style={{ minWidth: "25%" }}
															scrolling
															search
															deburr
															value={sample.genre}
															error={!sample.genre}
															onChange={(e, data) => {
																const samples = { ...readerData.samples } || {};
																samples[sampleId].genre = data.value;
																samples[sampleId].title = null;

																this.relayChange(null, {
																	name: "samples",
																	value: samples,
																});
															}}
														/>
														<audio
															src={sample.file}
															controls
															onPlay={(e) => {
																const allAudio = document.querySelectorAll("audio");

																for (const audio of allAudio) {
																	if (audio !== e.target) audio.pause();
																}
															}}
														/>
													</div>
													{sample.title && !sample.genre && <small>{sample.title}</small>}
												</div>
											);
										})}
							</>
						) : (
							<div
								className="field"
								style={{
									background: "#fafafa",
									padding: 10,
									borderRadius: 5,
									border: "1px solid #eee",
								}}
							>
								<label>{t("soundFile")}</label>
								<div className="reader-sample-new">
									<RecordButton
										onChange={(file) => {
											const newSample = { ...this.state.newSample };
											newSample.file = file;
											this.setState({ newSample });
										}}
									/>
									<Form.Select
										options={genreOptions}
										inline
										placeholder={t("stateGenre")}
										error={!this.state.newSample.genre}
										value={this.state.newSample.genre || ""}
										onChange={(e, data) => {
											const newSample = { ...this.state.newSample };
											newSample.genre = data.value;
											this.setState({ newSample });
										}}
									/>
									<br />
									<Button
										style={{
											marginTop: 10,
											opacity: !(this.state.newSample.genre && this.state.newSample.file)
												? 0.2
												: 1,
										}}
										color="blue"
										content={this.state.sampleUpload || t("saveSoundSample")}
										onClick={(e) => {
											e.preventDefault();
											if (!(this.state.newSample.genre && this.state.newSample.file)) {
												this.setState({
													newSampleErrors: true,
												});
											} else {
												this.addSample();
											}
										}}
									/>

									<Button
										color="grey"
										labelPosition="left"
										icon="delete"
										content={t("close")}
										floated="right"
										onClick={(e) => {
											e.preventDefault();
											this.setState({ newSample: false });
										}}
									/>
								</div>
							</div>
						)}
					</div>

					<UserLanguage user={profile} handleChange={this.props.handleChange} self={self} />
				</Form.Group>
				<Right can="createProducerStaff">
					<Form.Group inline>
						<Form.Checkbox
							label={
								t("cured") +
								" " +
								(readerData.curated ? moment(toDate(readerData.curated)).format("lll") : "")
							}
							name="searchable"
							checked={!!readerData.curated}
							onChange={(e, data) => {
								this.relayChange(e, { name: "curated", value: data.checked ? new Date() : null });
							}}
						/>
					</Form.Group>
				</Right>
				<Form.Group>
					<div className="field three wide">
						<label>{t("birthDate")}</label>
						<DateTime
							value={
								profile.dateOfBirth
									? profile.dateOfBirth.toDate &&
									  moment(toDate(profile.dateOfBirth)).format("YYYY-MM-DD")
									: ""
							}
							timeFormat={false}
							onChange={(dt) => {
								if (typeof dt === "object") {
									// valid date, save it
									this.props.handleChange(null, {
										name: "dateOfBirth",
										value: moment(dt.format("YYYY-MM-DD")).toDate(),
									});
								}
							}}
							onBlur={(dt) => {
								const writtenDate = moment(dt).format("YYYY-MM-DD");
								// see if typed date is valid and not the same as currently is in the db
								if (
									writtenDate !== "Invalid date" &&
									writtenDate !== moment(toDate(profile.dateOfBirth)).format("YYYY-MM-DD")
								) {
									// valid date, save it
									this.props.handleChange(null, {
										name: "dateOfBirth",
										value: moment(writtenDate).toDate(),
									});
								} else {
									console.log("Invalid date", dt);
								}
							}}
						/>
						{profile.dateOfBirth && (
							<em style={{ display: "block", marginTop: 5 }}>
								{t("age")}{" "}
								{profile.dateOfBirth?.toDate && moment().diff(toDate(profile.dateOfBirth), "years")}
							</em>
						)}
					</div>

					<div className="field">
						<label>{t("sex")}</label>
						<Form.Radio
							label={t("male")}
							name="gender"
							checked={profile.gender === "male"}
							value="male"
							onChange={this.relayChangeProfile}
						/>
						<Form.Radio
							label={t("female")}
							name="gender"
							checked={profile.gender === "female"}
							value="female"
							onChange={this.relayChangeProfile}
						/>
						<Form.Radio
							label={t("other")}
							name="gender"
							checked={profile.gender === "other"}
							value="other"
							onChange={this.relayChangeProfile}
						/>
					</div>
					<Right can="createProducerStaff" profile={this.props.user}>
						<div className="field">
							<label>{t("voiceMode")}</label>
							<input
								type="range"
								min={1}
								max={5}
								value={readerData.voice || 0}
								onChange={(e) => this.relayChange(e, { name: "voice", value: e.target.value })}
							/>

							{!!readerData.voice && (
								<em style={{ marginLeft: 4, display: "inline-block", verticalAlign: "text-bottom" }}>
									{readerData.voice +
										": " +
										[t("veryDark"), t("dark"), t("neutral"), t("light"), t("veryLight")][
											readerData.voice - 1
										]}
								</em>
							)}
						</div>
					</Right>
				</Form.Group>
				<Right can="createProducerStaff" profile={this.props.user}>
					<Form.Group widths="equal">
						<Form.Input
							fluid
							type="number"
							label={t("feePerHour", { currency: readerData?.currency || "N/A" })}
							placeholder={t("fee")}
							name="cost"
							value={readerData.cost || ""}
							onChange={this.relayChange}
						/>
						<Form.Select
							fluid
							label={t("currency")}
							name="currency"
							value={readerData.currency || "N/A"}
							options={codes.map((code) => ({ text: code, value: code }))}
							onChange={this.relayChange}
						/>
						<Form.Input
							fluid
							type="number"
							label={t("ratioPercentage")}
							placeholder={t("ratio")}
							name="ratio"
							value={readerData.ratio || ""}
							onChange={this.relayChange}
						/>
						<Form.Input
							fluid
							type="number"
							label={t("lossPercentage")}
							placeholder={t("loss")}
							name="loss"
							value={readerData.loss || ""}
							onChange={this.relayChange}
						/>
					</Form.Group>

					<Header as="h4" icon="sliders" content={t("prodCompPreferences")} />
					<Form.Group widths="equal">
						{store.state.users && (
							<Form.Select
								search
								deburr
								fluid
								label={t("productionManager")}
								name={"manager"}
								value={get(readerData, "producerSettings." + adminProducerOrg + ".manager") || ""}
								options={[
									{ key: "NONE", value: "", text: t("noOneChoosen") },
									...(admins || [])
										.sort((a, b) =>
											store.state.users[a] &&
											store.state.users[b] &&
											store.state.users[a].firstName > store.state.users[b].firstName
												? 1
												: -1,
										)
										.map((id) => ({
											key: id,
											value: id,
											text:
												store.state.users[id] &&
												store.state.users[id].firstName + " " + store.state.users[id].lastName,
										})),
								]}
								noResultsMessage={t("noHits")}
								onChange={(e, data) => {
									this.producerSettingChange(e, adminProducerOrg, data);
								}}
							/>
						)}

						<Form.Input
							label={t("memoChoices")}
							name={"memo"}
							value={get(readerData, "producerSettings." + adminProducerOrg + ".memo") || ""}
							onChange={(e, data) => {
								this.producerSettingChange(e, adminProducerOrg, data);
							}}
						/>
						<div className="field">
							<label>{t("sound")}</label>
							<MasterSoundSetting
								location="users"
								locationId={uid}
								locationName={profile.firstName + " " + profile.lastName}
								label={t("sound")}
								userId={get(store, "state.user.uid")}
								value={{ list: profile?.reaperTemplates?.list }}
							/>
						</div>
					</Form.Group>

					{profile.stats && (
						<>
							<Header as="h4" icon="line graph" content={t("statistics")} />
							<Statistic.Group size="mini">
								<Popup
									inverted
									size="mini"
									content={
										<>
											<p>{t("wordsPerRecHour")} Ord per inspelad timme</p>
											{t("average") + ":"} {+profile.stats.tempo.average.toFixed(2)}
											<br />
											{t("median")} {+profile.stats.tempo.median.toFixed(2)}
											<br />
											{t("fivePercAvg")} {+profile.stats.tempo.mean5.toFixed(2)}
											<br />
											{t("tenPercAvg")} {+profile.stats.tempo.mean10.toFixed(2)}
											<br />
											{t("twentyPercAvg")} {+profile.stats.tempo.mean20.toFixed(2)}
											<br />
										</>
									}
									trigger={
										<Statistic>
											<Statistic.Value>
												<Icon name="file text" color="teal" />{" "}
												{profile.stats.tempo.average.toFixed()}
											</Statistic.Value>
											<Statistic.Label>{t("tempo")}</Statistic.Label>
										</Statistic>
									}
								/>
								<Popup
									inverted
									size="mini"
									content={
										<>
											<p>{t("producedTimePerStuHour")}</p>
											{t("average")} {+profile.stats.ratio.average.toFixed(2)}%
											<br />
											{t("median")} {+profile.stats.ratio.median.toFixed(2)}%
											<br />
											{t("fivePercAvg")} {+profile.stats.ratio.mean5.toFixed(2)}%
											<br />
											{t("tenPercAvg")} {+profile.stats.ratio.mean10.toFixed(2)}%
											<br />
											{t("twentyPercAvg")} {+profile.stats.ratio.mean20.toFixed(2)}%
											<br />
										</>
									}
									trigger={
										<Statistic>
											<Statistic.Value>
												<Icon name="microphone" color="teal" />{" "}
												{profile.stats.ratio.average.toFixed()}%
											</Statistic.Value>
											<Statistic.Label>{t("ratio")}</Statistic.Label>
										</Statistic>
									}
								/>
								<Popup
									inverted
									size="mini"
									content={
										<>
											<p>{t("lostStudTime")} Förlorad studiotid</p>
											{t("average")} {+profile.stats.loss.average.toFixed(2)}%
											<br />
											{t("median")} {+profile.stats.loss.median.toFixed(2)}%
											<br />
											{t("fivePercAvg")} {+profile.stats.loss.mean5.toFixed(2)}%
											<br />
											{t("tenPercAvg")} {+profile.stats.loss.mean10.toFixed(2)}%
											<br />
											{t("twentyPercAvg")} {+profile.stats.loss.mean20.toFixed(2)}%
											<br />
										</>
									}
									trigger={
										<Statistic>
											<Statistic.Value>
												<Icon name="trash alternate" color="teal" />{" "}
												{profile.stats.loss.average.toFixed()}%
											</Statistic.Value>
											<Statistic.Label>{t("loss")}</Statistic.Label>
										</Statistic>
									}
								/>

								<Popup
									inverted
									size="mini"
									content={
										<>
											<p>{t("recTitles")}</p>
										</>
									}
									trigger={
										<Statistic>
											<Statistic.Value>
												<Icon name="book" color="orange" /> {profile.stats.total.productions}
											</Statistic.Value>
											<Statistic.Label>{t("titles")}</Statistic.Label>
										</Statistic>
									}
								/>
								<Popup
									inverted
									size="mini"
									content={
										<>
											<p>{t("recsInStud")}</p>
										</>
									}
									trigger={
										<Statistic>
											<Statistic.Value>
												<Icon name="calendar" color="orange" /> {profile.stats.total.sessions}
											</Statistic.Value>
											<Statistic.Label>{t("sessions")}</Statistic.Label>
										</Statistic>
									}
								/>
								<Popup
									inverted
									size="mini"
									content={
										<>
											<p>{t("totPlayTime")}</p>
										</>
									}
									trigger={
										<Statistic>
											<Statistic.Value>
												<Icon name="play" color="orange" />{" "}
												{Math.round(profile.stats.total.duration / 60 / 60)} h
											</Statistic.Value>
											<Statistic.Label>{t("playTime")}</Statistic.Label>
										</Statistic>
									}
								/>
								<Popup
									inverted
									size="mini"
									content={
										<>
											<p>{t("totStudTime")}</p>
										</>
									}
									trigger={
										<Statistic>
											<Statistic.Value>
												<Icon name="cube" color="orange" />{" "}
												{Math.round(profile.stats.total.studiotime / 60 / 60)} h
											</Statistic.Value>
											<Statistic.Label>{t("studTime")}</Statistic.Label>
										</Statistic>
									}
								/>
								<Popup
									inverted
									size="mini"
									content={
										<>
											<p>{t("readPages")}</p>
										</>
									}
									trigger={
										<Statistic>
											<Statistic.Value>
												<Icon name="file audio" color="orange" /> {profile.stats.total.pages}
											</Statistic.Value>
											<Statistic.Label>{t("pages")}</Statistic.Label>
										</Statistic>
									}
								/>
							</Statistic.Group>
						</>
					)}
				</Right>
			</Form>
		);
	}
}

export default withTranslation()(withStore(UserReader));
