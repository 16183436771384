import audibleDE from "./audibleDE";
import audibleUK from "./audibleUK";
import axiellFtp from "./axiellFtp";
import bod from "./bod";
import bokbasen from "./bokbasen";
import bokbasenApi from "./bokbasenApi";
import bonnierMp3 from "./bonnierMp3";
import bonnierWav from "./bonnierWav";
import bookbeat from "./bookbeat";
import bookmate from "./bookmate";
import bookwire from "./bookwire";
import clipsource from "./clipsource";
import elisa from "./elisa";
import ellibs from "./ellibs";
import fabel from "./fabel";
import kungligaBiblioteket_audio from "./kungligaBiblioteket_audio";
import kungligaBiblioteket_ebook from "./kungligaBiblioteket_ebook";
import landsbokasafn from "./landsbokasafn";
import litres from "./litres";
import nextory from "./nextory";
import nfg from "./nfg";
import optimal from "./optimal";
import overdrive from "./overdrive";
import polaris from "./polaris";
import publit from "./publit";
import storytel from "./storytel";
import storytelFilesDotCom from "./storytelFilesDotCom";
import storytelOld from "./storytelOld";
import storytool from "./storytool";
import storytoolMp3 from "./storytoolMp3";
import storytoolWav from "./storytoolWav";

export default {
	bookbeat,
	storytel,
	storytelFilesDotCom,
	storytelOld,
	litres,
	nextory,
	overdrive,
	ellibs,
	elisa,
	fabel,
	audibleDE,
	audibleUK,
	bookmate,
	bokbasen,
	bokbasenApi,
	axiellFtp,
	storytool,
	storytoolWav,
	storytoolMp3,
	publit,
	// publitFTP,	// PUBLIT FTP IS BROKEN. lot's of error messages on file delivery.
	bonnierWav,
	bonnierMp3,
	bod,
	optimal,
	polaris,
	landsbokasafn,
	nfg,
	bookwire,
	clipsource,
	kungligaBiblioteket_audio,
	kungligaBiblioteket_ebook,
};
