import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form, Message } from "semantic-ui-react";

import { base, firebase } from "astrid-firebase";

class UserEmail extends Component {
	/* buttonText = {
		confirm: this.props.admin ? "Spara" : this.props.t("changeEmail"),
		//login: this.props.t("Logga in och bekräfta"),
		login: "Logga in och bekräfta",
		loading: "Sparar...",
		done: "Ändrat!",
	}; */

	buttonText = (id) => {
		const { t } = this.props;

		const btnStrings = {
			confirm: this.props.admin ? t("save") : t("confirm"),
			login: t("loginAndConfirm"),
			loading: t("saves"),
			done: t("changed"),
		};
		return btnStrings[id];
	};

	state = {
		email: this.props.profile.email,
		enabled: false,
		text: this.buttonText("confirm"),
		requirePassword: false,
		requireAuth: false,
	};

	setEmail = (user) => {
		user.updateEmail(this.state.email)
			.then(() => {
				console.log("Email updated", user.uid);
				base.updateDoc("users/" + user.uid, {
					email: this.state.email,
				});
				this.setState({
					enabled: false,
					text: this.buttonText("done"),
					requirePassword: false,
					requireAuth: false,
				});
			})
			.catch((error) => {
				console.log("Email error", error);
				if (error.code === "auth/requires-recent-login") {
					// need to reauthenticate
					if (this.props.profile.loginMethod === "email") {
						this.setState({
							requirePassword: true,
							enabled: true,
							text: this.buttonText("login"),
						});
					} else {
						// reauthenticate with auth popup
						this.authPopup(user);
					}
				}
			});
	};

	sendResetEmail = () => {
		firebase
			.auth()
			.sendPasswordResetEmail(this.props.profile.email)
			.then(() => {
				this.setState({ emailResetByAdmin: true });
			})
			.catch((error) => {
				console.log("did not send reset email", error);
			});
	};

	authPopup = (user) => {
		if (!user) user = firebase.auth().currentUser;
		user.reauthenticateWithPopup(new firebase.auth.GoogleAuthProvider())
			.then((res) => {
				// successful auth, retry changing email
				this.setEmail(user, this.state.email);
			})
			.catch((err) => {
				// popup blocked, show button
				console.log(err);
				this.setState({
					enabled: true,
					text: this.buttonText("login"),
					requireAuth: true,
				});
			});
	};

	adminSubmit = (e) => {
		const { t } = this.props;
		// check if email is taken
		base.get("users", {
			context: this,
			query: (ref) => ref.where("email", "==", this.state.email),
		})
			.then((data) => {
				// alreade taken
				//window.alert(t("windowalertEmailTakenBy") + data[0].firstName + " " + data[0].lastName + "!");
				window.alert(t("windowalertEmailTakenBy", { first: data[0].firstName, last: data[0].lastName }));

				this.setState({ email: this.props.profile.email });
			})
			.catch((err) => {
				// not taken, go for it!
				this.setState({
					text: this.buttonText("loading"),
					enabled: false,
				});
				base.updateDoc("users/" + this.props.uid, {
					email: this.state.email,
					emailChangedByAdmin: true,
				}).then(() => {
					base.updateDoc("tasks/setNewEmails", {
						time: firebase.firestore.FieldValue.serverTimestamp(),
						starter: "Admin on profile",
					});
					this.setState({
						text: this.buttonText("confirm"),
					});
				});
			});
	};

	handleSubmit = (e) => {
		const user = firebase.auth().currentUser;

		this.setState({
			enabled: false,
			text: this.buttonText("loading"),
		});

		if (this.state.requirePassword) {
			// reauth with password
			const password = e.target.querySelector("[name=password]").value;
			const credential = firebase.auth.EmailAuthProvider.credential(user.email, password);
			user.reauthenticateWithCredential(credential).then((res) => {
				this.setEmail(user, this.state.email);
			});
		} else {
			// change instantly?
			this.setEmail(user, this.state.email);
		}
	};

	render() {
		const { t } = this.props;
		return (
			<Form onSubmit={this.props.admin ? this.adminSubmit : this.handleSubmit}>
				<Form.Group inline>
					<Form.Input
						placeholder={t("email")}
						name="newEmail"
						type="email"
						width={5}
						autoComplete="email"
						value={this.state.email}
						onChange={(e, data) => {
							this.setState({
								email: data.value,
								enabled: this.props.profile.email !== e.target.value,
								text: this.buttonText("confirm"),
							});
						}}
					/>
					{this.state.requirePassword && (
						<Form.Input
							placeholder={t("password")}
							name="password"
							type="password"
							autoComplete="password"
							autoFocus
						/>
					)}
					<Form.Button
						disabled={!this.state.enabled || this.props.profile.emailChangedByAdmin}
						content={this.state.text}
						color={this.state.requireAuth ? "google plus" : "blue"}
						icon={this.state.requireAuth ? "google" : "mail"}
						onClick={(e) => {
							if (this.state.requireAuth) this.authPopup();
						}}
					/>
				</Form.Group>
				{(this.state.requirePassword || this.state.requireAuth) && (
					<small style={{ color: "#a33" }}>{t("logInAgain")}</small>
				)}
				{this.props.admin && this.props.profile.emailChangeError && (
					<Message negative>{this.props.profile.emailChangeError}</Message>
				)}
				{this.props.admin && (
					<Form.Button
						disabled={this.props.profile.emailChangedByAdmin}
						loading={this.props.profile.emailChangedByAdmin}
						icon="send"
						labelPosition="left"
						content={this.state.emailResetByAdmin ? t("sent") + "!" : t("sendByEmail")}
						color="teal"
						onClick={(e) => {
							e.preventDefault();
							this.sendResetEmail();
						}}
					/>
				)}
			</Form>
		);
	}
}

export default withTranslation()(UserEmail);
