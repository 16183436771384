import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Form, Image } from "semantic-ui-react";

import { firebase, hasRight } from "astrid-firebase";

import { withStore } from "../../helpers/context";

import UploadButton from "../UploadButton";

class UserDetails extends Component {
	state = {
		upload: null,
		uploadedImg: null,
	};

	uploadImage = (e) => {
		const { t } = this.props;
		const storageRef = firebase
			.storage()
			.refFromURL(window.ES.stage ? "gs://stage-earselect-uploads" : "gs://earselect-uploads");

		// file from input or drop
		const file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];

		if (!file) return;

		if (file.type && file.type.substr(0, 5) !== "image") {
			this.setState({ upload: t("notAnImg") });
			return;
		}

		// start upload
		const uploadTask = storageRef.child("profileImages/" + this.props.uid).put(file);

		// upload status events
		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				// progress
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				this.setState({ upload: progress + "%" });
			},
			(error) => {
				// error
				console.log(error);
			},
			() => {
				// success, store in db
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					this.setState({ upload: t("processing") + "...", uploadedImg: downloadURL });
					this.props.handleChange(null, [{ name: "img", value: downloadURL }]);
				});
			},
		);
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { t } = this.props;
		if (this.state.uploadedImg && nextProps.profile.img !== this.state.uploadedImg) {
			this.setState({ upload: t("ready") + "!", uploadedImg: null });
		}
	}

	render() {
		const { phoneInvalid } = this.state;
		const { t, store, profile, handleChange, self } = this.props;
		const canEdit = self || hasRight(store, "userProfile.edit");

		return (
			<Form loading={false} as="div" style={{ maxWidth: 470 }}>
				<Form.Input
					fluid
					label={t("firstName")}
					placeholder={t("firstName")}
					name="firstName"
					value={profile.firstName}
					onChange={handleChange}
					readOnly={!canEdit}
				/>
				<Form.Input
					fluid
					label={t("lastName")}
					placeholder={t("lastName")}
					name="lastName"
					value={profile.lastName}
					onChange={handleChange}
					readOnly={!canEdit}
				/>
				<div className={"field" + (phoneInvalid ? " error" : "")}>
					<label>{t("telephoneNr")}</label>
					<PhoneInput
						placeholder={t("telephoneNr")}
						value={profile.phone || ""}
						defaultCountry="SE"
						readOnly={!canEdit}
						onChange={(value) => {
							this.setState({ phoneInvalid: false });
							if (!value) value = "";
							handleChange(null, { name: "phone", value });
						}}
						onBlur={() => {
							if (profile.phone && !isValidPhoneNumber(profile.phone))
								this.setState({ phoneInvalid: true });
						}}
					/>
				</div>
				{canEdit && (
					<UploadButton
						label={t("profilePic")}
						text={t("upload")}
						onUpload={this.uploadImage}
						style={{ display: "inline-block" }}
					/>
				)}

				{profile.img && (
					<>
						<Image src={profile.img} avatar spaced style={{ width: "80px", height: "80px" }} />
						{this.state.upload && <em>{this.state.upload}</em>}
					</>
				)}

				<Form.Checkbox
					name="notifications"
					label={t("sendEmailNotifications", "Send email notifications from Astrid")}
					value="email"
					checked={profile.notifications !== "none"}
					onChange={(e, data) => {
						data.value = data.checked ? "email" : "none";
						handleChange(e, data);
					}}
					readOnly={!canEdit}
					style={{ marginTop: 10 }}
				/>
				{/* {profile.producer && (
					<Form.Radio
						name="notifications"
						label={"Slack" + (profile.slack ? ` (@${profile.slack})` : "")}
						value="slack"
						checked={profile.notifications === "slack"}
						onChange={handleChange}
						readOnly={!canEdit}
					/>
				)}

				<Form.Radio
					name="notifications"
					label="Inga"
					value="none"
					checked={profile.notifications === "none"}
					onChange={handleChange}
					readOnly={!canEdit}
				/>
				*/}
			</Form>
		);
	}
}

export default withTranslation()(withStore(UserDetails));
