import React, { Component } from "react";
//id's in reader.json
import { withTranslation } from "react-i18next";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";

import { newUser } from "astrid-firebase";

import { withStore } from "../helpers/context";

class ReaderSignup extends Component {
	state = {
		message: false,
	};

	render() {
		const { t } = this.props;
		return (
			<Grid textAlign="center">
				<Grid.Column style={{ marginTop: "5%", maxWidth: 550 }}>
					{this.props.signedIn ? (
						<div style={{ marginBottom: "2em", paddingBottom: "2em", borderBottom: "2px solid #eee" }}>
							{t("inviteNarratorShareLink")}
							<div
								style={{
									width: "100%",
									fontSize: 20,
									margin: "1em 0",
									userSelect: "all",
									border: "1px solid #666",
									borderRadius: 3,
									background: "white",
									padding: 10,
									overflow: "auto",
								}}
							>
								{window.location.href + "/" + this.props.store.state.user.uid}
							</div>
							{t("narratorFormExplanation")}
						</div>
					) : (
						<>
							<Header as="h1" textAlign="center">
								{t("registerAsNarrator")}
							</Header>
							<Segment
								padded={window.innerWidth > 600 ? "very" : null}
								style={{ textAlign: "left", overflow: "hidden" }}
							>
								<Form
									size="large"
									onSubmit={(e, data) => {
										if (this.props.signedIn) {
											return;
										}
										const firstName = e.target.querySelector("input[name=firstName]").value;
										const lastName = e.target.querySelector("input[name=lastName]").value;
										const email = e.target.querySelector("input[name=email]").value;

										this.setState({ email });

										newUser(this, firstName, lastName, email, true);
									}}
								>
									<Form.Group widths="equal">
										<Form.Input
											required
											icon="user"
											iconPosition="left"
											fluid
											placeholder={t("firstName")}
											name="firstName"
										/>
										<Form.Input
											required
											icon="user"
											iconPosition="left"
											fluid
											placeholder={t("lastName")}
											name="lastName"
										/>
									</Form.Group>
									<Form.Input
										fluid
										icon="mail"
										iconPosition="left"
										placeholder={t("email")}
										name="email"
										type="email"
									/>

									<Button color="blue" floated="right" disabled={this.props.signedIn}>
										{t("createUser")}
									</Button>
								</Form>
								{this.state.message && (
									<em>
										{this.state.message.content}{" "}
										{this.state.message.negative && (
											<a href={"/login/email?email=" + this.state.email}>{t("clickToLogin")}</a>
										)}
									</em>
								)}
							</Segment>
						</>
					)}
				</Grid.Column>
			</Grid>
		);
	}
}

export default withTranslation()(withStore(ReaderSignup));
