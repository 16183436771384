import { Menu } from "semantic-ui-react";

export default function ChannelsMenu({ selectedChannel, setSelectedChannel }) {
	const channels = [
		{
			key: "stable",
			name: "Stable",
		},
		{
			key: "beta",
			name: "Beta",
		},
		{
			key: "alpha",
			name: "Alpha",
		},
	];

	return (
		<Menu fluid widths={3} secondary size="large" style={{ justifyContent: "center" }}>
			{channels.map((channel) => (
				<Menu.Item
					key={channel.key}
					name={channel.name}
					active={selectedChannel === channel.key}
					onClick={() => setSelectedChannel(channel.key)}
				/>
			))}
		</Menu>
	);
}
