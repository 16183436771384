import get from "lodash/get";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown, Header, Icon, Label } from "semantic-ui-react";

import prodGenres from "astrid-config/src/genres/storytel";

class UserGenre extends Component {
	render() {
		const { t, title, handleChange, profile } = this.props;
		const userData = profile.userData || get(profile, "readerData.userData") || {};

		const genreOptions = Object.entries(prodGenres.sv)
			.filter(([key]) => !userData.genre || !userData.genre.includes(key))
			.map(([key, genre]) => ({
				key: key,
				value: key,
				//text: genre,
				text: t(key),
			}));

		// add empty genre option for cancelling (first option is automatically selected...)
		genreOptions.unshift({
			key: "empty",
			value: null,
			text: t("cancelGenreChoice"),
		});

		return (
			<>
				<Header as="h4" icon="book" content={title || t("knowlAndPref")} />
				<Dropdown
					button
					labeled
					icon="plus"
					className="icon teal"
					text={t("addGenre")}
					scrolling
					floating
					pointing
					value={null}
					name={"genre"}
					options={genreOptions}
					onChange={(e, data) => {
						const genres = [...(userData.genre || [])];

						if (data.value && (e.type !== "keydown" || e.key === "Enter")) {
							genres.push(data.value);
							userData.genre = genres;

							handleChange(null, { name: "userData", value: userData });
						}
					}}
				/>
				<div style={{ marginTop: 10, minHeight: 30 }}>
					{userData.genre &&
						Array.isArray(userData.genre) &&
						!!userData.genre.length &&
						userData.genre.map((genre) => (
							<Label key={genre} style={{ marginTop: 4 }}>
								{t("genre:" + genre)}{" "}
								<Icon
									name="delete"
									onClick={(e) => {
										const genres = [...(userData.genre || [])];

										const i = genres.indexOf(genre);
										genres.splice(i, 1);
										userData.genre = genres;

										handleChange(null, {
											name: "userData",
											value: userData,
										});
									}}
								/>
							</Label>
						))}
				</div>
			</>
		);
	}
}

export default withTranslation()(UserGenre);
