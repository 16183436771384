import { Segment } from "semantic-ui-react";

import App from "./components/App";

export default function AppsView() {
	return (
		<div style={{ margin: "0 auto", maxWidth: 500 }}>
			<Segment padded="very">
				<App app="astrid-studio" name="Astrid Studio" />
			</Segment>
			{/*<Segment padded="very">*/}
			{/*	<App app="astrid-sync" name="Astrid Sync" />*/}
			{/*</Segment>*/}
		</div>
	);
}
