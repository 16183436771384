import isAnyRecorderInTeamOrOpenPosition from "astrid-web/src/features/calendar/helpers/isAnyRecorderInTeamOrOpenPosition";

import sessionErrors from "../constants/sessionErrors";
import sessionStatuses from "../constants/sessionStatuses";

export function isStudioOccupied({ session, studio }) {
	return session.studio === studio?.id;
}

export function isReaderOccupied({ session, reader }) {
	return reader.some((reader) => session.reader.includes(reader));
}

export function isRecorderOccupied({ session, recorder }) {
	return !isAnyRecorderInTeamOrOpenPosition({ recorder }) && session.recorder === recorder;
}

export function getSessionOverlapError({ sessions, ref, studio, reader, recorder, start, end }) {
	for (const session of sessions) {
		if (
			session.status !== sessionStatuses.CANCELLED &&
			session.id !== ref?.id &&
			session.studio &&
			session.start?.toDate() < end &&
			session.end?.toDate() > start
		) {
			if (isStudioOccupied({ session, studio })) {
				return sessionErrors.STUDIO_OCCUPIED;
			}

			if (isReaderOccupied({ session, reader })) {
				return sessionErrors.READER_OCCUPIED;
			}

			if (isRecorderOccupied({ session, recorder })) {
				return sessionErrors.RECORDER_OCCUPIED;
			}
		}
	}
}

export function isSessionOverlapping(props) {
	return !!getSessionOverlapError(props);
}
