import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import { firebase } from "astrid-firebase";

class UserPassword extends Component {
	state = {
		enabled: true,
		text: false,
	};

	UNSAFE_componentWillMount() {
		const { t } = this.props;
		if (this.props.profile.loginMethod === "google") {
			this.setState({
				text: t("youLoggedInViaGoogleBut"),
			});
		}
	}

	render() {
		const { t } = this.props;
		return (
			<div>
				<Button
					color="blue"
					icon="asterisk"
					labelPosition="left"
					content={t("chooseNewPassw")}
					onClick={(e) => {
						console.log("reset", this.props.profile.email);
						const email = this.props.profile.email;

						firebase
							.auth()
							.sendPasswordResetEmail(email, {
								url: "https://astrid.fm/login/email?email=" + email,
							})
							.then(() => {
								this.setState({
									enabled: false,
									text: t("youGotInstByMail"),
								});
							})
							.catch((error) => {
								console.log("password mail err", error);
							});
					}}
				/>
				{this.state.text && (
					<div style={{ maxWidth: 350, marginTop: 10 }}>
						<small>{this.state.text}</small>
					</div>
				)}
			</div>
		);
	}
}

export default withTranslation()(UserPassword);
