import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown, Header, Icon } from "semantic-ui-react";

import languages from "astrid-config/src/languages";
import { hasRight } from "astrid-firebase";

import { withStore } from "../../helpers/context";

class UserLanguage extends Component {
	render() {
		const { t, store, user, handleChange, self } = this.props;

		const canEdit = self || hasRight(store, "userProfile.edit");

		const langOptions = Object.keys(languages)
			.filter((code) => !user.languages || !user.languages[code])
			.map((code) => ({
				key: code,
				value: code,
				text: (languages[code].sv || languages[code].name) + " (" + languages[code].nativeName + ")",
			}));

		// add empty language option for cancelling (first option is automatically selected...)
		langOptions.unshift({
			key: "empty",
			value: null,
			text: t("cancelLangChoice"),
		});

		return (
			<div className="field" style={{ marginTop: "1em" }}>
				<Header as="h4" icon="flag" content={t("langKnowl")} />
				{canEdit && (
					<div>
						<Dropdown
							className="user-language-select icon teal"
							disabled={Object.values(user.languages || {}).find(({ skill }) => skill === 5)}
							labeled
							icon="plus"
							button
							floating
							pointing
							search
							deburr
							value={null}
							text={t("addMotherTongue")}
							options={langOptions}
							onChange={(e, data) => {
								const languages = { ...user.languages } || {};

								if (data.value && (e.type !== "keydown" || e.key === "Enter")) {
									languages[data.value] = { skill: 5 };
									handleChange(null, { name: "languages", value: languages });
								}
							}}
							onOpen={(e) => {
								// stupid hack to maintain width while selecting
								if (e && e.currentTarget && e.currentTarget.style)
									e.currentTarget.style.minWidth = e.currentTarget.offsetWidth + "px";
							}}
							onClose={(e) => {
								if (e && e.currentTarget && e.currentTarget.style) e.currentTarget.style.minWidth = "";
							}}
						/>
					</div>
				)}
				{user.languages &&
					Object.keys(user.languages)
						.filter((code) => user.languages[code].skill > 4)
						.map((code) => (
							<div
								key={code}
								className="user-language field"
								style={{
									display: "inline-block",
									margin: "1em 1em 0 0",
									padding: ".5em",
									borderRadius: "3px",
									background: "#eee",
								}}
							>
								{canEdit && (
									<a
										href="/"
										onClick={(e) => {
											e.preventDefault();

											const languages = { ...user.languages } || {};
											delete languages[code];
											handleChange(null, { name: "languages", value: languages });
										}}
									>
										<Icon name="trash alternate" color="black" />
									</a>
								)}

								{/* <label style={{ display: "inline-block" }}>
									{languages[code].sv || languages[code].name} ({languages[code].nativeName})
								</label> */}

								<label style={{ display: "inline-block" }}>{t("language:" + code)}</label>
								<br />
								<input
									value={user.languages[code].dialect || ""}
									onChange={(e) => {
										const languages = { ...user.languages } || {};
										languages[code].dialect = e.target.value;
										handleChange(e, { name: "languages", value: languages });
									}}
									placeholder={t("dialect")}
									style={{
										display: "inline-block",
										width: "100%",
										padding: "3px 4px 2px",
									}}
									readOnly={!canEdit}
								/>
							</div>
						))}

				{canEdit && (
					<div>
						<Dropdown
							className="user-language-select icon teal"
							labeled
							icon="plus"
							button
							floating
							pointing
							search
							deburr
							value={null}
							text={t("addLangKnowl")}
							style={{ marginTop: 15 }}
							options={langOptions}
							onChange={(e, data) => {
								const languages = { ...user.languages } || {};

								if (data.value && (e.type !== "keydown" || e.key === "Enter")) {
									languages[data.value] = { skill: 2 };
									handleChange(null, { name: "languages", value: languages });
								}
							}}
							onOpen={(e) => {
								// stupid hack to maintain width while selecting
								if (e && e.currentTarget && e.currentTarget.style)
									e.currentTarget.style.minWidth = e.currentTarget.offsetWidth + "px";
							}}
							onClose={(e) => {
								if (e && e.currentTarget && e.currentTarget.style) e.currentTarget.style.minWidth = "";
							}}
						/>
					</div>
				)}
				{user.languages &&
					Object.keys(user.languages)
						.filter((code) => user.languages[code].skill < 5)
						.map((code) => (
							<div key={code} className="user-language field">
								{canEdit && (
									<a
										href="/"
										onClick={(e) => {
											e.preventDefault();

											const languages = { ...user.languages } || {};
											delete languages[code];
											handleChange(null, { name: "languages", value: languages });
										}}
									>
										<Icon name="trash alternate" color="black" />
									</a>
								)}
								{/* <label style={{ display: "inline-block" }}>
									{languages[code].sv || languages[code].name} ({languages[code].nativeName})
								</label> */}
								<label style={{ display: "inline-block" }}>{t("language:" + code)}</label>
								<br />
								<input
									value={user.languages[code].dialect || ""}
									onChange={(e) => {
										const languages = { ...user.languages } || {};
										languages[code].dialect = e.target.value;
										handleChange(e, { name: "languages", value: languages });
									}}
									placeholder={t("dialect")}
									style={{
										display: "inline-block",
										width: "100%",
										padding: "3px 4px 2px",
									}}
									readOnly={!canEdit}
								/>
								<br />

								{canEdit && (
									<input
										type="range"
										min={1}
										max={4}
										value={user.languages[code].skill}
										onChange={(e) => {
											const languages = { ...user.languages } || {};
											languages[code].skill = +e.target.value;
											handleChange(null, { name: "languages", value: languages });
										}}
									/>
								)}
								<br />
								{/* <em
									style={{
										marginLeft: 4,
										display: "inline-block",
										verticalAlign: "text-bottom",
									}}
								>
									{userData.language[code] + ": " + languageSkill[userData.language[code] - 1]}
								</em> */}
								<em
									style={{
										marginLeft: 4,
										display: "inline-block",
										verticalAlign: "text-bottom",
									}}
								>
									{user.languages[code].skill +
										": " +
										//t("language:skillLevel" + languageSkill[userData.language[code]])}
										t("skillLevel" + user.languages[code].skill)}
								</em>
							</div>
						))}
			</div>
		);
	}
}

export default withTranslation(["common", "language"])(withStore(UserLanguage));
