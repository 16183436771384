import { useState } from "react";
import { Header } from "semantic-ui-react";

import ChannelReleases from "./ChannelReleases";
import ChannelsMenu from "./ChannelsMenu";

export default function App({ app, name }) {
	const [selectedChannel, setSelectedChannel] = useState("alpha");

	return (
		<>
			<Header as="h2" textAlign="center">
				{name}
			</Header>

			<ChannelsMenu selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel} />

			<ChannelReleases app={app} channel={selectedChannel} />
		</>
	);
}
