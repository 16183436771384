import distributors from "astrid-config/src/distributors";

import {
	getActiveArticles,
	getArticleData,
	getArticleISBN,
	getArticleImg,
	getArticleName,
	getArticleReleaseDate,
	getArticleReleaseDistributorValue,
	getArticleType,
} from "./articles";
import { getArticleDistributionOptions, getArticleDistributionSettings } from "./distributionOptions";
import { getArticleMasterSettings } from "./exports";

function checkCover({ articleDistributionSettings, distributorSettings }) {
	return (
		distributorSettings?.cover &&
		!distributorSettings.excludeMeta &&
		articleDistributionSettings?.automatic &&
		!articleDistributionSettings?.excludeCover &&
		!articleDistributionSettings?.excludeMeta
	);
}

function checkReleaseDate({ articleDistributionSettings, distributorSettings, releaseDate }) {
	return (
		distributorSettings?.meta &&
		!distributorSettings.excludeMeta &&
		articleDistributionSettings?.automatic &&
		!articleDistributionSettings?.excludeMeta &&
		!releaseDate
	);
}

function checkPricing({ articleDistributionSettings, distributorSettings, production, article, distributor }) {
	return (
		distributorSettings?.meta &&
		!distributorSettings?.excludeMeta &&
		articleDistributionSettings?.automatic &&
		!articleDistributionSettings?.excludeMeta &&
		distributorSettings?.meta?.requiredFields?.find(
			(field) =>
				field.substr(0, 5) === "price" &&
				!getArticleReleaseDistributorValue(production, article, distributor, field),
		)
	);
}

export function getArticleDeliveryIssues({ production, article, distributor }) {
	const deliveryIssues = [];

	const articleType = getArticleType(article);
	const articleMasterSettings = getArticleMasterSettings(production, article);
	const articleDistributionSettings = getArticleDistributionSettings({ production, article, distributor });

	const distributorSettings = distributors[distributor];

	const name = getArticleName(article);
	const isbn = getArticleISBN(production, article);
	const img = getArticleImg(production, article);
	const articleData = getArticleData(production, article);
	const releaseDate = getArticleReleaseDate(production, article, distributor);

	// ISBN
	const requireISBN = !distributorSettings?.allowMissingISBN;

	if (requireISBN && !isbn) {
		deliveryIssues.push(`Ange ISBN för ${name}`);
	}

	// cover
	const requireImg = checkCover({ articleDistributionSettings, distributorSettings });

	if (requireImg && !img) {
		deliveryIssues.push(`Ladda upp omslag för ${name}`);
	}

	// release date
	const missingReleaseDate = checkReleaseDate({ articleDistributionSettings, distributorSettings, releaseDate });

	if (missingReleaseDate) {
		deliveryIssues.push(`Ange släppdatum hos samtliga distributörer för ${name}`);
	}

	// pricing
	const missingPricing = checkPricing({
		articleDistributionSettings,
		distributorSettings,
		production,
		article,
		distributor,
	});

	if (missingPricing) {
		deliveryIssues.push(`Ange prissättning för samtliga distributörer för ${name}`);
	}

	switch (articleType) {
		case "total":
			if (!production.deliveryDuration) {
				deliveryIssues.push("specifyTotDuration");
			}

			break;
		case "part":
			if (!articleData.duration) {
				deliveryIssues.push("specifyDurationForParts");
			}

			if (!articleMasterSettings?.approved) {
				deliveryIssues.push("uploadMasterAndSaveExpForEachPart");
			}
			break;
		case "cd":
			if (!articleMasterSettings?.approved) {
				deliveryIssues.push("uploadIntroAndSaveCdExports");
			}

			if (distributorSettings.ifcd && !production?.deliveryCD?.ifcd) {
				deliveryIssues.push("specifyIfcdCdNr");
			}
			break;
		case "mp3cd":
			if (!articleMasterSettings?.approved) {
				deliveryIssues.push("saveExportsForMp3");
			}

			if (distributorSettings.ifcd && !production?.deliveryMP3CD?.ifcd) {
				deliveryIssues.push("specifyIfcdNrForMp3");
			}
			break;
		default:
			break;
	}

	return deliveryIssues;
}

export function getDeliveryIssues(production) {
	return getActiveArticles(production).flatMap((article) =>
		getArticleDistributionOptions({ production, article })?.flatMap((distributor) =>
			getArticleDeliveryIssues({ production, article, distributor }),
		),
	);
}
