import debounce from "lodash/debounce";
import get from "lodash/get";
import trimStart from "lodash/trimStart";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Grid, Header, Segment } from "semantic-ui-react";

import { base } from "astrid-firebase";
import selectHasRight from "astrid-permissions/selectors/selectHasRight";
import selectHasRole from "astrid-permissions/selectors/selectHasRole";

import DocTitle from "../components/DocTitle";
import Right from "../components/Right";
import UserDetails from "../components/user/UserDetails";
import UserEmail from "../components/user/UserEmail";
import UserGenre from "../components/user/UserGenre";
import UserLanguage from "../components/user/UserLanguage";
import UserPassword from "../components/user/UserPassword";
import UserReader from "../components/user/UserReader";
import { withStore } from "../helpers/context";

class Profile extends Component {
	state = {
		loading: true,
		self: true,
		uid: "",
		localProfile: {},
		ref: null,
	};

	connectToFirestore = (props) => {
		// figure out if own profile or someone elses
		const self = !(props.match.params.userId && props.match.params.userId !== props.user.uid);
		const uid = self ? props.user.uid : props.match.params.userId;

		// bind to state
		const ref = base.bindDoc("users/" + uid, {
			context: this,
			state: "localProfile",
			then() {
				this.setState({ self, uid, loading: false });
			},
			onFailure(err) {
				console.error(err);
			},
		});

		// store ref to unmount when switching from own to elses profile
		this.setState({ ref });
	};

	UNSAFE_componentWillMount() {
		this.connectToFirestore(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.match.params.userId !== this.props.match.params.userId) {
			// navigated from own to elses profile, remount profile
			base.removeBinding(this.state.ref);
			this.setState({
				loading: true,
				self: false,
				uid: "",
				localProfile: {},
				ref: null,
			});
			this.connectToFirestore(nextProps);
		}
	}

	updateFirestore = (ref, data) => {
		// update database
		base.updateDoc(ref, {
			...data,
			updatedBySelf: this.state.self, // MEMO: debug thing, can be removed
		});
	};

	debouncedUpdate = debounce((ref, data) => {
		// debounced method for keystrokes
		this.updateFirestore(ref, data);
	}, 400);

	handleChange = (e, data, log) => {
		// put single value in array
		if (!Array.isArray(data)) data = [data];

		// clone current data
		const localProfile = { ...this.state.localProfile };
		const newData = {};

		// loop all data fields
		data.forEach((dataPiece) => {
			const field = dataPiece.name;

			// set new data
			if (typeof dataPiece.value === "string") {
				// trim space from start for strings
				dataPiece.value = trimStart(dataPiece.value);
			}

			newData[field] = dataPiece.value;

			// update state and database
			localProfile[field] = dataPiece.value;
			this.setState({ localProfile });
		});

		if (log) {
			// log instead?
			console.log(newData);
		} else if (e) {
			// debounce browser event updates (keystrokes)
			this.debouncedUpdate("users/" + this.state.uid, { ...newData });
		} else {
			// update immediately
			this.updateFirestore("users/" + this.state.uid, { ...newData });
		}
	};

	render() {
		const { self, localProfile, uid } = this.state;
		const { profile, user, t, store } = this.props;
		const { producerId } = store.state;

		const hasRight = selectHasRight(profile, producerId);
		const hasRole = selectHasRole(profile);

		return (
			<>
				<DocTitle
					title={
						this.state.loading
							? "Profil"
							: self
							? "Din profil"
							: localProfile.firstName + " " + localProfile.lastName
					}
				/>

				{hasRole("owner", "admin") && <Link to={`/admin/users/${uid}`}>{"< " + t("admin", "Admin")}</Link>}
				<h1>{self ? t("yourProfile") : t("usersProfile")}</h1>

				{get(localProfile, "permissions.reader") &&
					profile.permissions &&
					(hasRight("readerRegistry.invite") || self) && (
						<Segment padded>
							<UserReader
								uid={uid}
								profile={localProfile}
								user={profile}
								self={self}
								handleChange={this.handleChange}
							/>
						</Segment>
					)}

				<Grid stackable columns="equal">
					<Grid.Column>
						<Segment padded loading={this.state.loading} className="profile-contact">
							<Header as="h4" icon="info" content={t("contacts")} />

							{!this.state.loading && (
								<UserDetails
									uid={uid}
									profile={localProfile}
									user={user}
									self={self}
									handleChange={this.handleChange}
								/>
							)}
							{/* {self && profile.producer && profile.notifications === "slack" && (
								<>
									<h5>Slack</h5>
									<UserSlack profile={profile} user={user} query={this.props.location.search} />
								</>
							)} */}

							<div className="ui form" style={{ marginTop: 15 }}>
								<div className="field">
									{self && localProfile.email && (
										<>
											<label>{t("email")}</label>
											<UserEmail profile={localProfile} />
											<UserPassword profile={localProfile} />
										</>
									)}

									{!self && localProfile.email && (
										<>
											<Right can="createProducerStaff">
												<label>{t("email")}</label>
												<UserEmail
													admin
													profile={localProfile}
													uid={this.props.match.params.userId}
												/>
											</Right>
											<Right not can="createProducerStaff">
												<label>{t("email")}</label>
												<a href={"mailto:" + localProfile.email}>{localProfile.email}</a>
											</Right>
										</>
									)}
								</div>
							</div>
						</Segment>
					</Grid.Column>
					<Grid.Column>
						{!get(localProfile, "permissions.reader") && get(localProfile, "permissions.producer") && (
							<Segment>
								{!get(localProfile, "permissions.publisherIndex") && (
									<UserGenre handleChange={this.handleChange} profile={localProfile} />
								)}
								<UserLanguage user={localProfile} handleChange={this.handleChange} self={self} />
							</Segment>
						)}
					</Grid.Column>
				</Grid>
			</>
		);
	}
}

//export default withStore(Profile);
export default withTranslation()(withStore(Profile));
