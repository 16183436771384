import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Card, Header, Icon, Label, Message } from "semantic-ui-react";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import sessionOfferTypes from "astrid-firestore/src/constants/sessionOfferTypes";

import Flex from "../../../../../components/Flex/Flex";
import { useProfile } from "../../../../authentication/state/profile";
import useHasOrganizationRole from "../../../../authorization/hooks/useHasOrganizationRole";

import recorderHasDefaultPrice from "../../../helpers/recorderHasDefaultPrice";
import tsToLocaleTimeString from "../../../helpers/tsToLocalTimeString";

export default function SessionInformation({ session, production }) {
	const { t } = useTranslation();
	const user = useProfile();
	const hasOrganizationRole = useHasOrganizationRole({
		user,
		organization: { type: organizationTypes.PRODUCER, id: session.producer },
	});

	const [active, setActive] = useState(true);

	const date = session.start?.toDate()?.toLocaleDateString();
	const start = tsToLocaleTimeString(session.start);
	const end = tsToLocaleTimeString(session.end);

	const translateRecorder = (session) => {
		switch (session.recorder) {
			default:
				return session?.recorderData?.name;
			case sessionOfferTypes.OPEN_POSITION_RECORDER:
				return t("upForGrabs", "Open position");
			case sessionOfferTypes.ANY_RECORDER_IN_TEAM:
			case null:
				return t("anyRecorderFromTeam", "Recording technicians in team");
		}
	};

	const sessionReader = Object.values(session.readerData || {})
		.map((reader) => reader.name)
		.join(", ");

	const sessionManager = session.managers?.map((manager) => manager.name).join(", ");

	if (
		hasOrganizationRole("producerRecorder") &&
		session.recorder !== user.id &&
		!recorderHasDefaultPrice({ production, user })
	) {
		return (
			<Message info>
				{t(
					"recorderMissingDefaultPrice",
					"You do not have a default price and can not view or grab this session",
				)}
			</Message>
		);
	}

	return (
		<>
			<Accordion.Title active={active} onClick={() => setActive(!active)}>
				<Header size="small">
					<Icon name="dropdown" />
					{t("sessionInformation", "Session information")}
				</Header>
			</Accordion.Title>
			<Accordion.Content active={active}>
				<Card fluid>
					<Card.Content>
						<Flex alignItems="stretch" style={{ flexDirection: "column", gap: 5 }}>
							<Label style={{ margin: 0 }}>
								<Icon style={{ width: 20 }} name="headphones" />
								{translateRecorder(session)}
							</Label>
							<Label style={{ margin: 0 }}>
								<Icon style={{ width: 20 }} name="microphone" />
								{sessionReader}
							</Label>
							<Label style={{ margin: 0 }}>
								<Icon style={{ width: 20 }} name="male" />
								{sessionManager}
							</Label>
							<Label style={{ margin: 0 }}>
								<Icon style={{ width: 20 }} name="cube" />
								{session.studioData ? session.studioData.name : t("noStudio", "No studio")}
							</Label>
							<Label style={{ margin: 0 }}>
								<Icon style={{ width: 20 }} name="calendar alternate" />
								{date}
							</Label>
							<Label style={{ margin: 0 }}>
								<Icon style={{ width: 20 }} name="clock " />
								{`${start} - ${end}`}
							</Label>
						</Flex>
					</Card.Content>
				</Card>
			</Accordion.Content>
		</>
	);
}
